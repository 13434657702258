import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { SignUpGuest } from 'domains/authorization/containers/guest';

const GuestPage: ApplicationPage = () => {
  return (
    <Page>
      <SignUpGuest />
    </Page>
  );
};

GuestPage.getMainLayoutProps = () => ({
  size: 'sm',
  centered: true,
  subheader: null,
});

GuestPage.getMeta = () => {
  return { title: 'Guest' };
};

export default GuestPage;
